import React from 'react';
import './LinkedInApp.css';
import avatar from '../../assets/images/pp.jpeg';
import hitemaLogo from '../../assets/images/LI_Logo/hitema.jpeg';
import sewanLogo from '../../assets/images/LI_Logo/sewan.jpeg';
import orangeLogo from '../../assets/images/LI_Logo/orange.jpeg';
import mathiasLogo from '../../assets/images/LI_Logo/mathias.jpg';
import yuboLogo from '../../assets/images/LI_Logo/yubo.jpeg';

const LinkedInApp = () => {
  return (
    <div className="linkedInApp">
      <LILink />
      <LIProfile />
      <div className="LI_separator" />
      <LIExperiences />
      <div className="LI_separator" />
      <LIEducation />
    </div>
  );
};

const LIProfile = () => {
  return (
    <div className="LI_profile">
      <div className="LI_banner"></div>
      <img className="LI_profilePicture" src={avatar} alt="avatar" />
      <LIProfileContent />
    </div>
  );
};

const LILink = () => {
  return (
    <a href="https://www.linkedin.com/in/jeremy-petit-a98323105/" target="_blank" rel="noreferrer" className="LI_link">
      Open profile on LinkedIn
    </a>
  );
};

const LIProfileContent = () => {
  return (
    <div className="LI_profileContent">
      <div className="LI_name">Jérémy Petit</div>
      <div className="LI_title">Senior Back End Engineer</div>
      <div className="LI_school">Hitema</div>
      <div className="LI_location">Versailles, Île-de-France, France</div>
      <div className="LI_connections">383 connections</div>
    </div>
  );
};

const LIExperiences = () => {
  return (
    <div className="LI_experiences">
      <div className="LI_experiencesTitle">Experience</div>
      <LIExperience
        title="Senior Back End Engineer"
        company="Yubo"
        duration="Jun 2022 - Nov 2023 (1 yr 6 mos)"
        location="Paris, Île-de-France, France"
        logo={yuboLogo}
      />
      <LIExperience
        title="Back End Engineer"
        company="Sewan Group"
        duration="Oct 2018 - Apr 2022 (3 yrs 7 mos)"
        location="Paris, Île-de-France, France"
        logo={sewanLogo}
      />
      <LIExperience
        title="R&D Full Stack Developer"
        company="Orange (Apprenticeship)"
        duration="Sep 2015 - Sep 2018 (3 yrs 1 mo)"
        location="Paris, Île-de-France, France"
        logo={orangeLogo}
      />
    </div>
  );
};

const LIExperience = ({ title, company, duration, description, logo }) => {
  return (
    <div className="LI_experience">
      <img src={logo} alt="logo" />
      <div className="LI_experience_details">
        <div className="LI_experience_title">{title}</div>
        <div className="LI_experience_company">{company}</div>
        <div className="LI_experience_duration">{duration}</div>
        <div className="LI_experience_description">{description}</div>
      </div>
    </div>
  );
};

const LIEducation = () => {
  return (
    <div className="LI_education">
      <div className="LI_educationTitle">Education</div>
      <LIEducationItem title="Master's degree" school="Hitema" duration="2015 - 2018" logo={hitemaLogo} />
      <LIEducationItem
        title="BTS Services informatiques aux organisations, Solutions Logicielles et Applications Métiers (SLAM)"
        school="Lycée Mathias"
        duration="2013 - 2015"
        logo={mathiasLogo}
      />
    </div>
  );
};

const LIEducationItem = ({ title, school, duration, logo }) => {
  return (
    <div className="LI_educationItem">
      <img src={logo} alt="logo" />
      <div className="LI_education_details">
        <div className="LI_education_school">{school}</div>
        <div className="LI_education_title">{title}</div>
        <div className="LI_education_duration">{duration}</div>
      </div>
    </div>
  );
};

export default LinkedInApp;
