import React from 'react';
import Slider from '../components/Slider';
import { formatTime, formatDate } from '../utils/helpers';

function LockScreen({ date, unlock }) {
  const formattedDate = formatDate(date);

  // const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const formattedTime = formatTime(date);

  return (
    <div className="screen lockScreen">
      <div className="date">{formattedDate}</div>
      <div className="time">{formattedTime}</div>
      <Slider unlock={unlock} />
    </div>
  );
}

export default LockScreen;
