import React from 'react';

const Notch = () => {
  return (
    <>
      <div className="notch-container" tabIndex="0">
        <div className="notch">
          <div className="content">
            <div className="left">
              <div className="tile"></div>
              <div className="text"></div>
            </div>
            <div className="right"></div>
            <div className="bar">
              <div className="duration"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="notch-blur"></div>
    </>
  );
};

export default Notch;
