import './App.css';
import React, { useState, useEffect } from 'react';

import Notch from './components/Notch';
import Phone from './components/Phone';
import TopBar from './components/TopBar';
import ActionBar from './components/ActionBar';

import HomeScreen from './screens/HomeScreen';
import LockScreen from './screens/LockScreen';
import AppScreen from './screens/AppScreen';

import PhoneApp from './components/Apps/PhoneApp';
import SettingsApp from './components/Apps/SettingsApp';
import LinkedInApp from './components/Apps/LinkedInApp';
import MessageApp from './components/Apps/MessageApp';

function App() {
  const [isLocked, setIsLocked] = useState(true);
  const [openApp, setOpenApp] = useState(null);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 10000);
    return function cleanup() {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="App">
      <Phone>
        <div className="bg"></div>
        <TopBar date={date} />
        <Notch />
        {isLocked && <LockScreen date={date} unlock={() => setIsLocked(false)} />}
        {openApp && (
          <AppScreen closeApp={() => setOpenApp(null)}>
            {openApp === 'Phone' ? (
              <PhoneApp />
            ) : openApp === 'Settings' ? (
              <SettingsApp />
            ) : openApp === 'Message' ? (
              <MessageApp />
            ) : (
              openApp === 'LinkedIn' && <LinkedInApp />
            )}
          </AppScreen>
        )}
        {!isLocked && (
          <HomeScreen
            openApp={(app) => {
              setOpenApp(app);
            }}
          />
        )}
        <ActionBar
          onClick={() => {
            setOpenApp(null);
          }}
        />
      </Phone>
    </div>
  );
}

export default App;
