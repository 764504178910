import React from 'react';
import { formatTime } from '../utils/helpers';

function TopBar({ date }) {
  const formattedTime = formatTime(date);
  return (
    <div className="topBar">
      <div className="left">{formattedTime}</div>
      <div className="right"></div>
    </div>
  );
}

export default TopBar;
