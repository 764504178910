import React, { useState } from 'react';
import { FaPhone, FaDeleteLeft } from 'react-icons/fa6';
import './PhoneApp.css';

const PhoneApp = () => {
  const [number, setNumber] = useState('');

  const DialerButton = ({ digit, letters }) => (
    <div
      className="dialerButton"
      onClick={() => {
        setNumber(number + digit);
      }}
    >
      <div>{digit}</div>
      <div>{letters}</div>
    </div>
  );

  return (
    <div className="phoneApp">
      <div className="numberInput">{number}</div>
      <div className="dialer">
        <div className="dialerRow">
          <DialerButton digit="1" />
          <DialerButton digit="2" letters="ABC" />
          <DialerButton digit="3" letters="DEF" />
        </div>
        <div className="dialerRow">
          <DialerButton digit="4" letters="GHI" />
          <DialerButton digit="5" letters="JKL" />
          <DialerButton digit="6" letters="MNO" />
        </div>
        <div className="dialerRow">
          <DialerButton digit="7" letters="PQRS" />
          <DialerButton digit="8" letters="TUV" />
          <DialerButton digit="9" letters="WXYZ" />
        </div>
        <div className="dialerRow">
          <DialerButton digit="*" />
          <DialerButton digit="0" letters="+" />
          <DialerButton digit="#" />
        </div>
        <div className="dialerRow">
          <div className="dialerButton hidden">
            <div></div>
          </div>
          <div className="dialerButton callButton">
            <FaPhone />
          </div>
          <div
            className="dialerButton delete"
            onClick={() => {
              setNumber(number.slice(0, -1));
            }}
          >
            {number.length > 0 && <FaDeleteLeft />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneApp;
