import React from 'react';
import SwitchButton from '../SwitchButton';
import {
  IoAirplane,
  IoBluetooth,
  IoNotificationsSharp,
  IoMoon,
  IoHourglass,
  IoVolumeHigh,
  IoChevronForward,
  IoSunny,
  IoBatteryFull,
} from 'react-icons/io5';
import { IoIosWifi, IoIosCellular, IoIosSwitch } from 'react-icons/io';
import { GrSettingsOption } from 'react-icons/gr';
import avatar from '../../assets/images/pp.jpeg';

import './SettingsApp.css';

// Header component
const Header = () => (
  <div className="settingsHeader">
    <img src={avatar} alt="avatar" />
    <div className="settingsHeaderInfo">
      <h2 className="settingsName">Lepetitdev</h2>
      <p>Backend developer</p>
    </div>
  </div>
);

// Settings item component
const SettingsItem = ({ Icon, background, name, text, switchButton, arrow = false }) => (
  <div className="settingsItem">
    <div className="settingsItemIcon" style={{ background }}>
      {Icon && <Icon />}
    </div>
    <div className="settingsItemName">{name}</div>
    <div className="settingsItemText">{text}</div>
    {switchButton && <SwitchButton />}
    {arrow && (
      <div className="settingsItemArrow">
        <IoChevronForward />
      </div>
    )}
    <div className="settingsItemSeparator"></div>
  </div>
);

// SettingsApp component
const SettingsApp = () => {
  return (
    <div className="settingsApp">
      <div className="settingsTitle">Settings</div>
      <Header />
      <div className="settingsSection"></div>
      <div className="settingsContent">
        <SettingsItem Icon={IoAirplane} background={'#ff9503'} name="Airplane Mode" switchButton />
        <SettingsItem Icon={IoIosWifi} background={'#007aff'} name="Wi-Fi" text="Starlink" arrow />
        <SettingsItem Icon={IoBluetooth} background={'#007aff'} name="Bluetooth" text="Off" arrow />
        <SettingsItem Icon={IoIosCellular} background={'#4dd964'} name="Cellular" arrow />
      </div>
      <div className="settingsSection"></div>
      <div className="settingsContent">
        <SettingsItem Icon={IoNotificationsSharp} background={'#ff3b2f'} name="Notifications" switchButton />
        <SettingsItem Icon={IoVolumeHigh} background={'#ff2c55'} name="Sounds & Haptics" arrow />
        <SettingsItem Icon={IoMoon} background={'#5856d7'} name="Focus" arrow />
        <SettingsItem Icon={IoHourglass} background={'#5856d7'} name="Screen Time" arrow />
      </div>
      <div className="settingsSection"></div>
      <div className="settingsContent">
        <SettingsItem Icon={GrSettingsOption} background={'#8e8e93'} name="General" arrow />
        <SettingsItem Icon={IoIosSwitch} background={'#8e8e93'} name="Control Center" arrow />
        <SettingsItem Icon={IoSunny} background={'#007aff'} name="Display & Brightness" arrow />
        <SettingsItem Icon={IoBatteryFull} background={'#4dd964'} name="Battery" arrow />
      </div>
    </div>
  );
};

export default SettingsApp;
