import React, { useState, useEffect } from 'react';

const Time = () => {
  const [times, setTimes] = useState([]);
  const cities = ['Paris', 'São Paulo', 'New York', 'Tokyo'];

  useEffect(() => {
    const timezones = ['Europe/Paris', 'America/Sao_Paulo', 'America/New_York', 'Asia/Tokyo'];

    async function fetchAndUpdateTimes() {
      function formatTime(date) {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
      }

      function getTimeInTimezone(timezone) {
        const date = new Date();
        const options = {
          timeZone: timezone,
        };
        return formatTime(new Date(date.toLocaleString('en-US', options)));
      }
      setTimes(timezones.map((timezone) => getTimeInTimezone(timezone)));
    }
    fetchAndUpdateTimes();

    const intervalId = setInterval(fetchAndUpdateTimes, 10000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="widget widgetTime">
      {times.map((time, index) => (
        <div className="cityTime" key={index}>
          <div className="city">{cities[index]}</div>
          <div className="time">{time}</div>
        </div>
      ))}
    </div>
  );
};

export default Time;
