import React from 'react';
import Meteo from '../components/Meteo';
import Time from '../components/Time';
import Apps from '../components/Apps';
import phoneIcon from '../assets/images/appIcons/phone.svg';
import mailIcon from '../assets/images/appIcons/mail.svg';
import settingsIcon from '../assets/images/appIcons/settings.svg';
import messageIcon from '../assets/images/appIcons/message.svg';
import githubIcon from '../assets/images/appIcons/github.webp';
import linkedinIcon from '../assets/images/appIcons/linkedin.webp';

function HomeScreen({ openApp }) {
  return (
    <div className="screen homeScreen">
      <Meteo />
      <Time />
      <Apps
        label="Phone"
        icon={phoneIcon}
        onClick={() => {
          openApp('Phone');
        }}
      />
      <Apps label="Message" icon={messageIcon} />
      <Apps label="Mail" icon={mailIcon} />
      <Apps
        label="Settings"
        icon={settingsIcon}
        onClick={() => {
          openApp('Settings');
        }}
      />
      <Apps label="GitHub" icon={githubIcon} />
      <Apps
        label="LinkedIn"
        icon={linkedinIcon}
        onClick={() => {
          openApp('LinkedIn');
        }}
      />
      <Apps />
      <Apps />
    </div>
  );
}

export default HomeScreen;
