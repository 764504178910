import React from 'react';

const Apps = ({ icon, label = '', onClick }) => {
  return (
    <div onClick={onClick} className="app">
      <div className="appOverlay" style={{ backgroundImage: `url(${icon})` }}></div>
      <span className="appLabel">{label}</span>
    </div>
  );
};

export default Apps;
