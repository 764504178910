import React from 'react';

function Phone({ children }) {
  return (
    <div className="scene">
      <div className="phone-con">
        <div className="phone">
          <div className="buttons">
            <div className="left">
              <div className="button"></div>
              <div className="button"></div>
              <div className="button"></div>
            </div>
            <div className="right">
              <div className="button"></div>
            </div>
          </div>
          <div className="camera"></div>
          <div className="screen-container">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Phone;
