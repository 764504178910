import React, { useState } from 'react';
import '../assets/styles/slider.css';

const Slider = ({ unlock }) => {
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [sliderPosition, setSliderPosition] = useState(0);

  const handleSlide = (event) => {
    const newPosition = event.target.value;
    setSliderPosition(newPosition);
    if (newPosition < 100) {
      setIsUnlocked(false);
    } else {
      setIsUnlocked(true);
      unlock();
    }
  };

  return (
    <div className="slider-container">
      <input type="range" min="0" max="100" value={sliderPosition} onChange={handleSlide} className="sliderUnlock" />
      <div className={`unlock-text ${isUnlocked ? 'unlocked' : ''}`}>
        {isUnlocked ? 'Unlocked!' : 'Slide to unlock'}
      </div>
    </div>
  );
};

export default Slider;
