import React, { useEffect, useState } from 'react';
import { getWeatherIcon } from '../utils/helpers';

const Meteo = () => {
  const [data, setData] = useState(null);
  const [geolocation, setGeolocation] = useState({ latitude: 52.52, longitude: 13.41 });
  const [city, setCity] = useState('Paris');

  useEffect(() => {
    const fetchData = async () => {
      if (geolocation.latitude !== 52.52 && geolocation.longitude !== 13.41) {
        try {
          const response = await fetch(
            `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${geolocation.latitude}&longitude=${geolocation.longitude}&localityLanguage=en`
          );
          const jsonData = await response.json();
          setCity(jsonData.city);
        } catch (error) {
          setCity('Paris');
          console.error('Error fetching city data:', error);
        }
      }

      try {
        const response = await fetch(
          `https://api.open-meteo.com/v1/forecast?latitude=${geolocation.latitude}&longitude=${geolocation.longitude}&current=temperature_2m,weather_code`
        );
        const jsonData = await response.json();
        setData({
          temperature: jsonData.current.temperature_2m,
          weather_code: jsonData.current.weather_code,
        });
      } catch (error) {
        setData({ temperature: 0, weather_code: 0 });
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [geolocation]);

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        setGeolocation({ latitude: lat, longitude: lng });
      });
    }
  }, []);

  return (
    <div className="widget">
      {data && (
        <div>
          <div className="city">{city}</div>
          <div className="temp">{Math.floor(data.temperature)}°</div>
          <div className="icon">{getWeatherIcon(data.weather_code)}</div>
        </div>
      )}
    </div>
  );
};

export default Meteo;
