import {
  TiWeatherCloudy,
  TiWeatherDownpour,
  TiWeatherNight,
  TiWeatherPartlySunny,
  TiWeatherShower,
  TiWeatherSnow,
  TiWeatherStormy,
  TiWeatherSunny,
  TiWeatherWindy,
} from 'react-icons/ti';

function getWeatherIcon(code) {
  switch (code) {
    case 0:
      return <TiWeatherSunny />;
    case 1:
      return <TiWeatherPartlySunny />;
    case 2:
    case 3:
      return <TiWeatherCloudy />;
    case 45:
    case 48:
      return <TiWeatherNight />;
    case 51:
    case 53:
    case 55:
      return <TiWeatherDownpour />;
    case 56:
    case 57:
      return <TiWeatherWindy />;
    case 61:
    case 63:
    case 65:
    case 66:
    case 67:
      return <TiWeatherShower />;
    case 71:
    case 73:
    case 75:
    case 77:
      return <TiWeatherSnow />;
    case 80:
    case 81:
    case 82:
      return <TiWeatherShower />;
    case 85:
    case 86:
      return <TiWeatherSnow />;
    case 95:
    case 96:
    case 99:
      return <TiWeatherStormy />;
    default:
      return <TiWeatherSunny />;
  }
}

const formatTime = (date) => {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

const formatDate = (date) => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const dayOfWeek = days[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = months[date.getMonth()];

  return `${dayOfWeek} ${dayOfMonth} ${month}`;
};

export { getWeatherIcon, formatTime, formatDate };
