import React from 'react';
import { IoCreateOutline } from 'react-icons/io5';
import avatar from '../../assets/images/pp.jpeg';
import './MessageApp.css';

const MessageApp = () => {
  return (
    <div className="messageApp">
      <div className="MsgTopBar">
        <div>Edit</div>
        <IoCreateOutline style={{ fontSize: '1.5em' }} />
      </div>
      <div className="MsgTitle">Messages</div>
      <div className="MsgContainer">
        <MessageComponent />
      </div>
    </div>
  );
};

const MessageComponent = () => {
  return (
    <div className="MsgComponent">
      <img className="MsgAvatar" src={avatar} alt="avatar" />
      <div className="MsgDetails">
        <div className="MsgName">Jérémy</div>
        <div className="MsgMessage">Welcome message</div>
      </div>
    </div>
  );
};

export default MessageApp;
